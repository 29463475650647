





















import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormCheckAll',
  props: {
    tabContent: {
      type: Object
    }
  },

  mixins: [PromoCampaignFormMixin],

  computed: {
    checkList(): any {
      return this.tabContent[this.formData.campaignType];
    }
  }
};
